/*
 * @Description: 公共组件示例
 * @Author: shufei
 * @Date: 2021-11-11 10:27:12
 * @LastEditTime: 2021-11-20 11:45:06
 * @LastEditors: shufei
 */

// 公共组件 示例路由数组
export default [
  {
    path: '/h5/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "about" */ '@/views/h5/map.vue'),
    meta: { title: '共富U乡', keepAlive: false, isTabBar: false }
  }
]
